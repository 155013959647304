import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_13/slide1';

import SubSlide1 from './slides/subSlide1';
import SubSlide2 from './slides/subSlide2';
import SubSlide3 from './slides/subSlide3';
import SubSlide4 from './slides/subSlide4';
import SubSlide5 from './slides/subSlide5';
import SubSlide6 from './slides/subSlide6';
import SubSlide7 from './slides/subSlide7';
import SubSlide8 from './slides/subSlide8';
import SubSlide9 from './slides/subSlide9';

const getRandom = () => {
  return Math.random();
}
const slides = [
  <SubSlide1 key={getRandom} />,
  <SubSlide2 key={getRandom} />,
  <SubSlide3 key={getRandom} />,
  <SubSlide4 key={getRandom} />,
  <SubSlide5 key={getRandom} />,
  <SubSlide6 key={getRandom} />,
  <SubSlide7 key={getRandom} />,
  <SubSlide8 key={getRandom} />,
  <SubSlide9 key={getRandom} />,
];


const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-13-0-mobile"}}) {
        body
      }
      file(relativePath: { eq: "chapter_4/sub_13/4-13-1-bg__mobile.png"}) {
        childImageSharp {
            fixed(width: 1920) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
      }
      timelineTitels: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "timelines2"}}) {
        exports {
          titleArray {
            index
            position
            text
          }
        }
      }
    }
  `);

  return (
    <Template query={query} slides={slides} title="Partie XIII" buttonText="Voir le cours de la guerre" />
  );
};


export default Slide;
